/**Created by liaoyingchao on 3/22/21.*/

<template>
  <div class="index" v-loading.lock="loading">
    <div class="base-table-main" ref="Ref_Base_Table">
      <slot v-bind:data="{ tableHeight: tableHeight, tableData: tableData, showOperation: showOperation }" v-if="tableHeight"></slot>
    </div>
    <div class="base-table-footer">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :currentPage="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="total">
      </el-pagination>
      <div style="flex: 1">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "index",
    components: {},
    props: {
      listApiFunction: {
        type: Function,
        default: null,
      },
      pageSize: {
        type: Number,
        default: 10,
      },
      defaultArgs: {
        type: Object,
        default: () => {
          return {}
        },
      },
      operationWidth: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        total: 0,
        tableHeight: 0,
        currentPage: 1,
        tableData: [],
        lastArgs: {},
        showOperation: true,
        zoomRight: 30,
        loading: false,
      }
    },
    methods: {
      zoomOptEvent() {
        this.showOperation = !this.showOperation;
        if (this.showOperation) {
          this.zoomRight = this.operationWidth
        } else {
          this.zoomRight = 30
        }
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.loadData()
      },
      filterEvent(args) {
        this.lastArgs = args || {}
        this.currentPage = 1
        this.loadData()
      },
      loadData() {
        this.lastArgs.pageNumber = this.currentPage
        this.lastArgs.pageSize = this.pageSize

        if (this.listApiFunction && typeof this.listApiFunction == 'function') {
          this.loading = true
          this.listApiFunction(this.lastArgs).then(res => {
            if (res.code == 0) {
              let page = res.page || {}
              this.total = page.totalCount || 0
              this.tableData = []
              this.$nextTick(() => {
                this.tableData = res.data
              })
            } else {
              this.$message.error(res.msg)
            }
          }).finally(() => {
            this.loading = false
          })
        }
      }
    },
    mounted() {
      this.lastArgs = this.defaultArgs || {}
      let dom = this.$refs.Ref_Base_Table
      this.tableHeight = dom.offsetHeight
      this.loadData()

      this.zoomRight = this.operationWidth
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped type="text/stylus">
  .index {
    width 100%;
    height 100%;
    display flex;
    flex-direction column;
    .base-table-main {
      width 100%;
      flex 1;
      position relative;
    }
    .base-table-footer {
      padding 10px;
      display flex;
      align-items center;
    }
  }
</style>